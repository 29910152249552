<template lang="pug">
	main#empreendimentos
		Listagem
</template>

<script>
import Listagem from '@sections/Empreendimentos/Listagem/Listagem'

export default {
	name: "view-empreendimentos",
	components: {
		Listagem,
	},
	metaInfo() {
		return {
			title: 'Propriedades | CA2A',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./Empreendimentos.styl"></style>